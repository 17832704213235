import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { StateContext } from "../contexts/State";
import DateCellWrapper from "../components/DateCellWrapper";
import EventWrapper from "../components/dashboard/EventWrapper";
import Navbar from "../components/layouts/Navbar";
import Hamburger from "../components/svg/Hamburger";
import CenteredModal from "../components/CenteredModal";
import AddIntervention from "../components/dashboard/AddIntervention";
import DayHeader from "../components/dashboard/DayHeader";
import Toolbar from "../components/Toolbar";
import { BarLoader } from "react-spinners";
import DownArrow from "../components/svg/DownArrow";
import OverflowMenuItem from "../components/dashboard/OverflowMenuItem";
import CalendarLegend from "../components/dashboard/CalendarLegend";
import { setBgColor, isWithinRestrictedTimes } from "../services/helpers";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import CalendarFilter from "../components/dashboard/CalendarFilter";
import { DateObject } from "react-multi-date-picker";
import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

const Dashboard = () => {
  const { state } = useContext(StateContext);
  const [user, setUser] = useState(state.currentUser.user);
  const [monthIndex, setMonthIndex] = useState(new Date().getMonth() + 1);
  const [yearIndex, setYearIndex] = useState(new Date().getFullYear());
  const [currMonthInterventions, setCurrMonthInterventions] = useState({});
  const [restrictedDays, setRestrictedDays] = useState([]);
  const [showNavbar, setShowNavbar] = useState(false);
  const [showMore, setShowMore] = useState({});
  const [showInterventionModal, setShowInterventionModal] = useState(false);
  const [interventionFilter, setInterventionFilter] =
    useState("all_interventions");
  const [loading, setLoading] = useState(true);
  const [showOverflowMenuItem, setShowOverflowMenuItem] = useState(null);
  const [times, setTimes] = useState({
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: null,
  });
  const [restrictedTimes, setRestrictedTimes] = useState({});
  const [teacherEditing, setTeacherEditing] = useState(false);
  const [adminDelete, setAdminDelete] = useState(false);
  const [allowMultipleInterventions, setAllowMultipleInterventions] =
    useState(false);
  const [allowMultipleSignups, setAllowMultipleSignups] = useState(false);
  const [restrictTeacherAccess, setRestrictTeacherAccess] = useState(false);
  // newInterventionDate is used to set the date cell selected from the calendar as the initial date of a new intervention.
  const [newInterventionDate, setNewInterventionDate] = useState("");
  const controller = useRef();
  // gets day of the week in text format
  const dayOfTheWeek = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
  }).format(new Date());

  // contains the details of the intervention copied from EventWrapper.jsx or EventDetails.jsx
  const [copiedInterventionDetails, setCopiedInterventionDetails] = useState(
    {}
  );
  // used to differentiate between a similarly named variable in EventWrapper.jsx
  // Is used to toggle on AddIntervention.jsx whether or not a user should see the "copy" UI.
  const [isCopiedInterventionWrapper, setIsCopiedInterventionWrapper] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!state.currentUser?.user) return;
    if (state.currentUser?.user?.role === 2) {
      setInterventionFilter("my_interventions");
    }
  }, [state.currentUser?.user]);

  useEffect(() => {
    setUser(state.currentUser.user);
  }, [state]);

  const getTimes = useCallback(async () => {
    if (state?.currentUser?.token && state?.currentUser?.user?.role !== 1) {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/time-limits`,
          {
            headers: {
              Authorization: "Bearer " + state?.currentUser?.token,
            },
          }
        );
        const res = await data.json();
        if (!res.error) {
          if (res?.[dayOfTheWeek]?.[0] && res?.[dayOfTheWeek]?.[0]) {
            const temp = new Date().toLocaleString().split(",")[0];
            const earliestTime = res[dayOfTheWeek][0];
            const latestTime = res[dayOfTheWeek][1];
            setRestrictedTimes([earliestTime, latestTime]);
            const isBetweenTimes = moment(new Date()).isBetween(
              new Date(`${temp}, ${earliestTime}`),
              new Date(`${temp}, ${latestTime}`)
            );
            setTimes({
              earliest_time: new Date(
                `${temp}, ${earliestTime}`
              ).toLocaleTimeString("en-US", {
                hour12: true,
                minute: "2-digit",
                hour: "numeric",
              }),
              latest_time: new Date(
                `${temp}, ${latestTime}`
              ).toLocaleTimeString("en-US", {
                hour12: true,
                minute: "2-digit",
                hour: "numeric",
              }),
            });
          }
        }
      } catch (error) {
        console.error("error getting signup times: ", error);
      }
    }
  }, [state?.currentUser?.token]);

  useEffect(() => {
    getTimes();
  }, [getTimes]);

  const getTeacherEditing = useCallback(async () => {
    if (state.currentUser?.token) {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/school-settings`,
          {
            headers: {
              Authorization: "Bearer " + state.currentUser?.token,
            },
          }
        );
        const res = await data.json();
        if (!res.error) {
          setTeacherEditing(res.allow_teacher_edit);
          setAllowMultipleInterventions(res.allow_multiple_interventions);
          setRestrictTeacherAccess(res.restrict_teacher_access);
          setAdminDelete(res.allow_admin_delete);
          setAllowMultipleSignups(res.allow_multiple_intervention_signups);
          return;
        } else if (
          (res.error && res.error === "JWT Signature Expired") ||
          res.error === "JWT Token Expired"
        ) {
          document.cookie =
            "tss_remember_me=keep_logged_in; max-age=0; SameSite=lax; path=/";
          navigate("/login");
        }
      } catch (error) {
        console.error("error getting admin editing permission: ", error);
      }
    }
  }, [state.currentUser?.token, navigate]);

  // If a student is a part of a teacher's exclusion list, that teacher's interventions will not
  // appear on the student's calendar.
  const isExcluded = (intervention, studentID) => {
    if (
      intervention?.exclusion_list &&
      intervention?.exclusion_list?.length > 0
    ) {
      if (intervention.exclusion_list.includes(studentID.toString())) {
        return true;
      } else return false;
    }
    return false;
  };

  const getInterventions = useCallback(
    async (yearNum, monthNum, signal) => {
      if (!state?.currentUser?.token || !state?.currentUser?.user?.role) {
        return;
      }
      let currMonthInterventions;
      try {
        setLoading(true);
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}v2/interventions/${yearNum}/${monthNum}`,
          {
            signal: signal,
            headers: {
              Authorization: "Bearer " + state.currentUser.token,
            },
          }
        );
        currMonthInterventions = await res?.json();

        setLoading(false);

        if (state.currentUser.user.role === 3) {
          for (let interventionArr in currMonthInterventions) {
            currMonthInterventions[interventionArr] = currMonthInterventions[
              interventionArr
            ].map((intervention) => {
              const startEnd = new Date(
                `${intervention.date}T10:00:00`
              ).toUTCString();
              const formattedIntervention = {
                ...intervention,
                color: "blue",
                start: startEnd,
                end: startEnd,
                title: intervention.name,
              };
              if (interventionArr === "attending") {
                formattedIntervention.color = "yellow";
              } else if (interventionArr === "closed_interventions") {
                formattedIntervention.color = "red";
              } else if (interventionArr === "open_interventions") {
                formattedIntervention.color = "blue";
              } else if (interventionArr === "required") {
                formattedIntervention.color = "gray";
              }
              return formattedIntervention;
            });
          }
          const dates = [];
          const requiredDates = [
            ...currMonthInterventions.attending,
            ...currMonthInterventions.required,
          ];
          for (let i = 0; i < requiredDates.length; i++) {
            if (dates.indexOf(requiredDates[i].date) === -1) {
              dates.push(requiredDates[i].date);
            }
          }

          // if a school doesn't allow students to sign up for multiple interventions, filter their calendar.
          // The filter will remove any interventions a student isn't attending on a given day
          if (!allowMultipleSignups) {
            dates.forEach((date) => {
              const closedInterventions =
                currMonthInterventions.closed_interventions;
              currMonthInterventions.closed_interventions =
                closedInterventions.filter((event) => event.date !== date);

              const openInterventions =
                currMonthInterventions.open_interventions;
              currMonthInterventions.open_interventions =
                openInterventions.filter((event) => event.date !== date);
            });
          }

          // make sure that only "open" private interventions don't appear for students.
          currMonthInterventions.open_interventions =
            currMonthInterventions.open_interventions.filter(
              (intervention) =>
                !intervention.private &&
                !isExcluded(intervention, state.currentUser?.user?.id)
            );

          setCurrMonthInterventions({
            all_interventions: [
              ...currMonthInterventions.attending,
              // ...closedInterventions,
              ...currMonthInterventions.open_interventions,
              ...currMonthInterventions.required,
            ],
          });
        } else {
          for (let interventionArr in currMonthInterventions) {
            currMonthInterventions[interventionArr] = currMonthInterventions[
              interventionArr
            ].map((intervention) => {
              return {
                ...intervention,
                color: "blue",
                start: new Date(`${intervention.date}T10:00:00`).toUTCString(),
                end: new Date(`${intervention.date}T10:00:00`).toUTCString(),
                title: intervention.name,
              };
            });
          }
          if (state.currentUser.user.role === 2) {
            setCurrMonthInterventions({
              all_interventions: [
                ...currMonthInterventions.all_interventions,
                ...currMonthInterventions.my_interventions,
              ],
              my_interventions: currMonthInterventions.my_interventions,
            });
          } else {
            setCurrMonthInterventions(currMonthInterventions);
          }
        }
      } catch (error) {
        console.error("error getting interventions: ", error);
        setLoading(false);
      }
    },
    [
      allowMultipleSignups,
      state.currentUser.token,
      state.currentUser.user?.id,
      state.currentUser.user?.role,
    ]
  );

  const fetchData = useCallback(
    async (signal) => {
      await getTeacherEditing();
      getInterventions(yearIndex, monthIndex, signal);
    },
    [getInterventions, getTeacherEditing, monthIndex, yearIndex]
  );

  // fetches current interventions - abortController cancels requests that may overlap, such as when a user quickly navigates through the calendar.
  useEffect(() => {
    if (controller.current) {
      setLoading(false);
      controller?.current.abort();
    }

    controller.current = new AbortController();
    const { signal } = controller.current;
    fetchData(signal);

    const unmountController = new AbortController();
    return () => {
      unmountController?.abort();
      console.log("aborting call...");
    };
  }, [fetchData]);

  const getRestrictedDays = useCallback(async () => {
    if (state?.currentUser?.token) {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_URL}school/restricted-day`,
          {
            headers: {
              Authorization: "Bearer " + state?.currentUser?.token,
            },
          }
        );
        const res = await data.json();
        if (!res.error) {
          if (typeof res === "object") {
            for (let day in res["Restricted Days"]) {
              restrictedDays.push(res["Restricted Days"].at(day)["Day"]);
            }
          }
        }
      } catch (error) {
        console.error("error getting restricted days: ", error);
      }
    }
  }, [state?.currentUser?.token]);

  useEffect(() => {
    getRestrictedDays();
  }, [getRestrictedDays]);

  const isTeacher = useCallback(() => {
    const role = state.currentUser?.user?.role;
    if (role === 1 || role === 2 || role === 4) {
      return true;
    } else return false;
  }, [state.currentUser?.user?.role]);

  const handleSelectSlot = useCallback(
    ({ start }) => {
      // If a school has the option to restrict teachers from making changes during restricted time,
      // and the user is either a Teacher or Staff
      const isRestrictedTime =
        isWithinRestrictedTimes(restrictedTimes) &&
        restrictTeacherAccess &&
        (state.currentUser?.user?.role === 2 ||
          state.currentUser?.user?.role === 4);

      let dateString = start.toISOString().split("T")[0];
      if (
        dateString &&
        !restrictedDays.includes(dateString) &&
        isTeacher() &&
        !isRestrictedTime &&
        !showInterventionModal
      ) {
        setNewInterventionDate(new DateObject(start));
        setShowInterventionModal(true);
        setNewInterventionDate("");
      }
    },
    [
      restrictedDays,
      isTeacher,
      restrictedTimes,
      restrictTeacherAccess,
      state.currentUser?.user?.role,
      showInterventionModal,
    ]
  );

  const eventSort = (a, b) => (a > b) - (a < b);

  try {
    return (
      <section>
        <Navbar open={showNavbar} />
        <div
          className="dashboard-content"
          onClick={() => {
            if (showNavbar) setShowNavbar(false);
          }}
        >
          <main className="page">
            <Hamburger
              className="hamburger-icon px-4 py-4 h-10 w-10"
              onClick={() => {
                setShowNavbar(true);
              }}
            />
            <div className="flex justify-between items-center">
              {isTeacher() && (
                <CalendarFilter
                  currentUser={state?.currentUser}
                  interventionFilter={interventionFilter}
                  setInterventionFilter={setInterventionFilter}
                  setShowInterventionModal={setShowInterventionModal}
                  restrictedTimes={restrictedTimes}
                  restrictTeacherAccess={restrictTeacherAccess}
                />
              )}
              <CalendarLegend role={state.currentUser?.user?.role} />
            </div>
            {times.earliest_time &&
              times.latest_time &&
              times.earliest_time !== "12:00 AM" &&
              times.earliest_time !== "12:01 AM" &&
              (restrictTeacherAccess &&
              (state.currentUser?.user?.role === 2 ||
                state.currentUser?.user?.role === 4) ? (
                <p className="text-sm my-2 text-tssGray-600">
                  <b>You will not be able to make changes</b> to interventions
                  between <b>{times.earliest_time}</b>
                  <span className="ml-1 mr-1">to</span>
                  <b>{times.latest_time}</b> on {dayOfTheWeek}s. If the current
                  time is outside this range, please refresh the page.
                </p>
              ) : (
                <p className="text-sm my-2 text-tssGray-600">
                  {state.currentUser?.user?.role === 3
                    ? "You will not be able to select interventions between "
                    : "Students will not be able to select interventions between "}
                  <b className="text-tssBlack-500">{times.earliest_time}</b>
                  <span className="ml-1 mr-1">to</span>
                  <b className="text-tssBlack-500">{times.latest_time}</b> on {dayOfTheWeek}s.
                </p>
              ))}
            {allowMultipleSignups && state.currentUser?.user?.role === 3 && (
              <p className=" text-sm my-2 text-tssGray-600">
                Your school has enabled signing up to multiple interventions in
                a day.
              </p>
            )}
            {loading && (
              <>
                <div className="loader-bg"></div>
                <div className="loader flex flex-col items-center gap-4">
                  <h2 className="mb-4 text-blueprimary-600 text-3xl">
                    Loading...
                  </h2>
                  <BarLoader color="#4383cb" />
                </div>
              </>
            )}
            <Calendar
              className="mb-6"
              components={{
                dateCellWrapper: (props) => (
                  <DateCellWrapper {...props} restrictedDays={restrictedDays} />
                ),
                eventWrapper: (props) => <EventWrapper {...props} />,
                showInterventionModal,
                setCurrMonthInterventions,
                restrictedTimes,
                teacherEditing,
                adminDelete,
                dayOfTheWeek,
                allowMultipleInterventions,
                restrictTeacherAccess,
                setCopiedInterventionDetails,
                setShowInterventionModal,
                setIsCopiedInterventionWrapper,
                month: {
                  dateHeader: (props) => (
                    <DayHeader {...props} restrictedDays={restrictedDays} />
                  ),
                },
                toolbar: (props) => (
                  <Toolbar
                    {...props}
                    setMonthIndex={setMonthIndex}
                    monthIndex={monthIndex}
                    yearIndex={yearIndex}
                    setYearIndex={setYearIndex}
                  />
                ),
              }}
              messages={{
                next: ">",
                previous: "<",
              }}
              localizer={localizer}
              events={currMonthInterventions[interventionFilter]?.sort(
                (a, b) => {
                  return (
                    eventSort(
                      a.owner_name?.toLowerCase(),
                      b.owner_name?.toLowerCase()
                    ) || eventSort(a.name?.toLowerCase(), b.name?.toLowerCase())
                  );
                }
              )}
              views={["month", "agenda"]}
              startAccessor="start"
              endAccessor="end"
              selectable="ignoreEvents"
              onSelectSlot={handleSelectSlot}
              onShowMore={(events, date) =>
                // setShowMore([true, events, date])
                setShowMore({
                  showMore: true,
                  events: events,
                  date: date,
                })
              }
            ></Calendar>

            {showMore.showMore && (
              <CenteredModal
                className="show-more__modal"
                onShowModal={() =>
                  setShowMore({
                    showMore: false,
                    events: [],
                    date: "",
                  })
                }
              >
                <p className="text-center mt-2">{showMore.events[0].date}</p>
                <section className="flex flex-col ">
                  {showMore.events
                    .sort((a, b) =>
                      a?.owner_name?.toLowerCase() >
                      b?.owner_name?.toLowerCase()
                        ? 1
                        : -1
                    )
                    .map((event, key) => (
                      <div
                        key={key}
                        className="flex items-center flex-col gap-2 py-1 px-2 rounded-md"
                      >
                        <div
                          className="text-white flex justify-between items-center px-2 rounded-md shadow-md w-full"
                          style={{ backgroundColor: setBgColor(event) }}
                          onClick={() =>
                            setShowOverflowMenuItem(
                              showOverflowMenuItem &&
                                showOverflowMenuItem === event.id
                                ? null
                                : event.id
                            )
                          }
                        >
                          <button className="text-left" type="button">
                            {event?.owner_name} - {event.name}
                          </button>
                          <DownArrow
                            className={"cursor-pointer w-3"}
                            onClick={() =>
                              setShowOverflowMenuItem(
                                showOverflowMenuItem &&
                                  showOverflowMenuItem === event.id
                                  ? null
                                  : event.id
                              )
                            }
                          />
                        </div>
                        {showOverflowMenuItem === event.id && (
                          <OverflowMenuItem
                            allowMultipleInterventions={
                              allowMultipleInterventions
                            }
                            showMenu={setShowMore}
                            event={event}
                            setCurrMonthInterventions={
                              setCurrMonthInterventions
                            }
                            allowTeacherEdit={teacherEditing}
                            allowAdminDelete={adminDelete}
                            restrictedTimes={restrictedTimes}
                            dayOfTheWeek={dayOfTheWeek}
                            restrictTeacherAccess={restrictTeacherAccess}
                            isCopiedInterventionWrapper={
                              isCopiedInterventionWrapper
                            }
                            setIsCopiedInterventionWrapper={
                              setIsCopiedInterventionWrapper
                            }
                            setCopiedInterventionDetails={
                              setCopiedInterventionDetails
                            }
                            setShowInterventionModal={setShowInterventionModal}
                          />
                        )}
                      </div>
                    ))}
                </section>
              </CenteredModal>
            )}
            {showInterventionModal && (
              <CenteredModal
                onShowModal={() => {
                  setShowInterventionModal(false);
                  setCopiedInterventionDetails({});
                }}
              >
                <AddIntervention
                  newInterventionDate={newInterventionDate}
                  restrictedDays={restrictedDays}
                  setShowInterventionModal={setShowInterventionModal}
                  setInterventions={setCurrMonthInterventions}
                  allowMultipleInterventions={allowMultipleInterventions}
                  copiedIntervention={copiedInterventionDetails}
                  isCopiedIntervention={isCopiedInterventionWrapper}
                  setIsCopiedIntervention={setIsCopiedInterventionWrapper}
                />
              </CenteredModal>
            )}
          </main>
        </div>
      </section>
    );
  } catch (error) {
    console.log("There was an error loading the UI: " + error);
    return "";
  }
};
export default Dashboard;
